<template>
    <div>
        <div class="card">
            <div class="flex flex-wrap justify-content-between align-content-center column-gap-2">
                <div class="flex align-items-center font-medium text-xl mr-8 md:pr-6 py-2">Booking Details</div>
                <div class="flex-order-1 md:flex-order-0 flex flex-grow-1 align-items-center justify-content-end mb-1">
                    <Button label="Confirm List" @click="confirmList" icon="pi pi-check" class="p-button-raised p-button-success w-full"/>
                </div>
                <div class="flex-order-2 md:flex-order-1 flex flex-grow-1 align-items-center justify-content-end mb-1">
                    <Button label="Challan" @click="challan" icon="pi pi-list" class="p-button-raised p-button-info w-full"/>
                </div>
                <div class="flex-order-3 md:flex-order-2 flex flex-grow-1 align-items-center justify-content-end lg:w-auto md:w-8 w-full mb-1">
                    <Dropdown
                        class="inputfield w-full"
                        id="type"
                        v-model="bookingStatus"
                        :options="bookingStatuses"
                        placeholder="Filter by status"
                        showClear
                        @change="getFilteredBookingList"
                    />
                </div>
                <!-- <div class="flex-order-0 md:flex-order-3 flex flex-grow-1 align-items-center justify-content-center mb-1">
                    <router-link class="w-full" :to="{ name: 'details', params: { slug: route.params.slug, id: route.params.id } }">
                        <Button icon="pi pi-arrow-left" class="p-button-raised p-button w-full"/>
                    </router-link>
                </div> -->
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border mt-auto" />
            <div class="grid-nogutter" v-if="pageLoading">
                <div class="col-12" v-for="i in 4" :key="i"><Skeleton class="p-3 m-2" height="8rem"/></div>
            </div>
            <div class="grid-nogutter" v-else-if="bookings && bookings.total > 0">
                <div class="col-12">
                    <div class="p-3 pb-0 mb-2 booking-item" v-for="(booking, i) in bookings.data" :key="i">
                        <div class="grid text-center md:text-left">
                            <div class="col-12 md:col-3">
                                <div class="flex flex-column">
                                    <div class="mb-2"><i class="pi pi-user"></i><span class="ml-2 font-bold">{{ booking.traveller_name }}</span></div>
                                    <div class="mb-2"><i class="pi pi-mobile"></i><span class="ml-2">{{ booking.traveller_mobile }}</span></div>
                                    <div class="mb-2"><i class="pi pi-envelope"></i><span class="ml-2" style="word-wrap: break-word;">{{ booking.traveller_email }}</span></div>
                                    <div class="mb-2"><i class="pi pi-clock"></i><span class="ml-2">{{ 'Created ' + timeDiff(booking.created_at) }}</span></div>
                                </div>
                            </div>
                            <div class="col-12 md:col-3">
                                <div class="flex mb-2 align-items-center justify-content-center md:justify-content-start">
                                    <div class="flex mr-2">{{ booking.number_of_adults }} Adults</div>
                                    <div class="flex mr-2" v-if="booking.number_of_children > 0">and {{ booking.number_of_children }} Children</div>
                                </div>
                                <!-- <div class="mb-2">
                                    <span>Total </span><span class=" font-semibold">BDT {{ booking.total_price.toLocaleString('en-BD') }}</span>
                                </div> -->
                                <div class="mb-2">
                                    <span>Total </span>
                                    <span class=" font-semibold">BDT {{ booking.total_price.toLocaleString('en-BD') }}</span>
                                </div>
                                <div class="mb-2">
                                    <span>Total Paid </span>
                                    <span class=" font-semibold">BDT {{ booking.total_paid.toLocaleString('en-BD') }}</span>
                                </div>
                                <div class="mb-2">
                                    <span>Due </span><span class=" font-semibold">BDT {{ (booking.total_price - booking.total_paid).toLocaleString('en-BD') }}</span>
                                </div>
                            </div>
                            <div class="col-12 md:col-4">
                                <div class="mb-2">
                                    <span>Payment </span><span class="font-semibold">{{ booking.payment_method }}</span>
                                </div>
                                <div class="mb-2">
                                    <span>Type </span>
                                    <span class="font-semibold" v-if="booking.booking_type == 'Operator'">{{ booking.booking_type }}</span>
                                    <span class="font-semibold" v-else>Customer</span>
                                </div>
                                <div class="mb-2">
                                    <span>Booked By </span>
                                    <span class="font-semibold" v-if="booking.booking_type == 'Operator'">{{ booking.creator.name }}</span>
                                    <span class="font-semibold" v-else>Self</span>
                                </div>
                                <div class="flex justify-content-center md:justify-content-start mb-2">
                                    <Tag :severity="bookingStatusColors(booking.status)" :value="booking.status" class="mr-2 px-2"/>
                                    <Button label="Cancel Booking" icon="pi pi-times" class="p-button-raised p-button-danger p-button-sm" @click="cancelBooking(booking)" v-if="booking.status == 'Requested for Cancellation'" />
                                </div>
                            </div>
                            <div class="col-12 md:col-2">
                                <Button class="p-button-raised p-button-text p-button-lg w-full text-center md:my-4" icon="pi pi-angle-down" type="button" label="Actions" @click="toggle($event, booking)" aria-haspopup="true" aria-controls="overlay_menu"/>
                                <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <Paginator
                        v-model:first="bookings.from"
                        :rows="paginationValue"
                        :totalRecords="bookings.total"
                        :rowsPerPageOptions="[10,20,50]"
                        @page="onPage"
                    />
                </div>
            </div>
            <div class="grid-nogutter" v-else>
                <div class="col">
                    <div class="card">
                        <div class="flex align-items-stretch justify-content-center flex-wrap card-container" style="min-height: 450px">
                            <div class="flex align-items-center justify-content-center">
                                <Message severity="info" :closable="false">You don't have any booking</Message>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Dialog v-model:visible="openCreatePayment" :style="{width: '650px'}" header="Create New Payment" :modal="true" class="p-fluid" @hide="closeCancellationDialog">
                <InlineMessage severity="info" class="text-left">Your due is: <span class="font-semibold">{{(selectedItem.total_price - selectedItem.total_paid)}}</span> BDT</InlineMessage>
                <div class="p-fluid grid mt-4">
                    <div class="field col-12 lg:col-6">
                        <span class="p-float-label p-fluid mb-0">
                            <InputNumber
                                id="amount"
                                showButtons
                                :step="1"
                                :min="1"
                                :max="(selectedItem.total_price - selectedItem.total_paid)"
                                incrementButtonIcon="pi pi-plus"
                                decrementButtonIcon="pi pi-minus"
                                :class="{ 'p-invalid': v$.amount.$invalid && submitted, }"
                                v-model="v$.amount.$model"
                                :disabled="selectedItem.total_price == selectedItem.total_paid"
                            />
                            <label for="amount">Amount</label>
                        </span>
                        <span v-if="v$.amount.$error && submitted">
                            <span
                                id="amount-error"
                                v-for="(error, index) of v$.amount.$errors"
                                :key="index"
                            >
                                <small class="p-error">{{error.$message.replace("Value", "Amount")}}</small>
                            </span>
                        </span>
                        <small
                            v-else-if="(v$.amount.$invalid && submitted) || v$.amount.$pending.$response"
                            class="p-error"
                        >
                            {{ v$.amount.required.$message.replace("Value", "Amount") }}
                        </small>
                    </div>
                    
                    <div class="field col-12 lg:col-6">
                        <span class="p-float-label p-fluid mb-0">
                            <Calendar
                                :class="{'p-invalid': v$.date.$invalid && submitted}"
                                id="date"
                                v-model="v$.date.$model"
                                dateFormat="dd-mm-yy"
                                :showButtonBar="true"
                                selectionMode="single"
                                :showIcon="true"
                            />
                            <label for="date">Date</label>
                        </span>
                        <span v-if="v$.date.$error && submitted">
                            <span
                                id="date-error"
                                v-for="(error, index) of v$.date.$errors"
                                :key="index"
                            >
                                <small class="p-error">{{error.$message}}</small>
                            </span>
                        </span>
                        <small
                            v-else-if="(v$.date.$invalid && submitted) || v$.date.$pending.$response"
                            class="p-error"
                        >
                            {{ v$.date.required.$message.replace("Value", "Date") }}
                        </small>
                    </div>
                    <div class="field col-12">
                        <span class="p-float-label p-fluid mb-0">
                            <Textarea
                                :class="{'p-invalid': v$.note.$invalid && submitted}"
                                id="note"
                                v-model="v$.note.$model"
                                :autoResize="true"
                            />
                            <label for="note">Note</label>
                        </span>
                        <span v-if="v$.note.$error && submitted">
                            <span
                                id="note-error"
                                v-for="(error, index) of v$.note.$errors"
                                :key="index"
                            >
                                <small class="p-error">{{error.$message}}</small>
                            </span>
                        </span>
                        <small
                            v-else-if="(v$.note.$invalid && submitted) || v$.note.$pending.$response"
                            class="p-error"
                        >
                            {{ v$.note.required.$message.replace("Value", "Note") }}
                        </small>
                    </div>
                </div>
            <template #footer>
                <Button label="Close" icon="pi pi-times" class="p-button-text" @click="hideCreatePayment"/>
                <Button label="Submit" icon="pi pi-check" class="p-button p-button-raised" @click="paymentSubmission(!v$.$invalid)" :loading="paymentLoading" />
            </template>
        </Dialog>

        <Dialog v-model:visible="cancelDialog" :style="{width: '650px'}" header="Cancel Booking" :modal="true" class="p-fluid" @hide="closeCancellationDialog">
            <div class="surface-section">
                <ul class="list-none p-0 m-0">
                    <li class="flex align-items-center py-3 md:px-2 border-top-1 surface-border flex-wrap">
                        <div class="text-500 w-8 md:w-3 font-medium">Cancelling Reason</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.cancelling_reason ? selectedItem.cancelling_reason : 'No cancellation reason found'}}</div>
                    </li>
                </ul>
            </div>
            <template #footer>
                <Button label="Decline Cancellation" icon="pi pi-times" class="p-button-raised p-button-danger" @click="DeclineCancellation" :loading="declineLoading"/>
                <Button label="Confirm Cancellation" icon="pi pi-check" class="p-button" @click="Cancellation" :loading="cancelLoading"/>
            </template>
        </Dialog>

        <Dialog v-model:visible="openPaymentList" :style="{width: '950px'}" header="Payment List" :modal="true" class="p-fluid" :closable="false">
            <div id="printable">
                <header style="text-align: center; margin-bottom: 15px;">
                    <table style="width: 100%; margin-bottom: 10px;">
                        <tr>
                            <td style="text-align: left">
                                <img v-if="agencyData.logo" :src="constant.imgUrl + agencyData.logo" alt="logo" role="presentation" height="80">
                                <img v-else src="../../../assets/logo-light-256.png" alt="Nishcinto" height="80">
                            </td>
                            <td style="text-align: right;font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;" id="header-info">
                                <div style="font-size: 12px;padding: 2px; font-weight: 600;">Payment List</div>
                                <div style="font-size: 12px;padding: 2px;">{{formatDate(today)}}</div>
                                <div style="font-size: 12px;padding: 2px;">Tour No. <span style="font-weight: 600;">T#00{{tour.id}}</span></div>
                                <div style="font-size: 12px;padding: 2px;">Traveller Name: <span style="font-weight: 600;">{{selectedItem.traveller_name}}</span></div>
                                <div style="font-size: 12px;padding: 2px;font-weight: 600;">{{tour.agency.name}}</div>
                            </td>
                        </tr>
                    </table>
                    <div style="background-color: #4285F4;padding: 10px;text-align: center;font-size: 20px;color: #fff;print-color-adjust: exact; margin: 8px 0;">{{ tour.title }}</div>
                </header>
                <section>
                    <article style="width: 100%; margin-top: 8px;">
                        <table style="width: 100%; border: 1px solid;  border-color: #BDBDBD; border-collapse: collapse; font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;">
                            <tr style="border: 1px solid;  border-color: #BDBDBD; border-collapse: collapse;">
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">#</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Amount</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Date</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Note</th>
                            </tr>
                            <tr style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse;" v-for="(item, index) in paymentList" :key="index">
                                <td style="width: 5%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ index+1 }}</td>
                                <td style="width: 10%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ item.amount }}</td>
                                <td style="width: 25%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ formatDate(item.date) }}</td>
                                <td style="width: 60%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: left;">{{ item.note }}</td>
                            </tr>
                        </table>
                        <div style="color: #808080;text-align: center;margin-top: 20px;font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;print-color-adjust: exact;">
                            <small>© NISHCINTO.COM. All rights reserved.</small>
                        </div>
                    </article>
                </section>
            </div>
            <template #footer>
                <Button label="Close" icon="pi pi-times" class="p-button-text" @click="hidePaymentList"/>
                <Button label="Print" icon="pi pi-print" class="p-button p-button-raised" @click="printTicket('Payment List')"/>
            </template>
        </Dialog>

        <Dialog v-model:visible="openList" :style="{width: '950px'}" header="Confirm List" :modal="true" class="p-fluid" :closable="false">
            <div id="printable">
                <header style="text-align: center; margin-bottom: 15px;">
                    <table style="width: 100%; margin-bottom: 10px;">
                        <tr>
                            <td style="text-align: left">
                                <img v-if="agencyData.logo" :src="constant.imgUrl + agencyData.logo" alt="logo" role="presentation" height="80">
                                <img v-else src="../../../assets/logo-light-256.png" alt="Nishcinto" height="80">
                            </td>
                            <td style="text-align: right;font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;" id="header-info">
                                <div style="font-size: 12px;padding: 2px; font-weight: 600;">Traveller List</div>
                                <div style="font-size: 12px;padding: 2px;">{{formatDate(today)}}</div>
                                <div style="font-size: 12px;padding: 2px;">Tour No. <span style="font-weight: 600;">T#00{{tour.id}}</span></div>
                                <div style="font-size: 12px;padding: 2px;">Manager: <span style="font-weight: 600;">{{tour.manager.name}}</span></div>
                                <div style="font-size: 12px;padding: 2px;font-weight: 600;">{{tour.agency.name}}</div>
                            </td>
                        </tr>
                    </table>
                    <div style="background-color: #4285F4;padding: 10px;text-align: center;font-size: 20px;color: #fff;print-color-adjust: exact; margin: 8px 0;">{{ tour.title }}</div>
                </header>
                <section>
                    <article style="width: 100%; margin-top: 8px;">
                        <table style="width: 100%; border: 1px solid;  border-color: #BDBDBD; border-collapse: collapse; font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;">
                            <tr style="border: 1px solid;  border-color: #BDBDBD; border-collapse: collapse;">
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">#</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Name</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Mobile</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Adults</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Child</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Type</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">T-shirts</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Booked By</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Remark</th>
                            </tr>
                            <tr style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse;" v-for="(item, index) in products.bookings" :key="index">
                                <td style="width: 3%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ item.serial }}</td>
                                <td style="width: 22%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact;">{{ item.traveller_name }}</td>
                                <td style="width: 12%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ item.traveller_mobile }}</td>
                                <td style="width: 5%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ item.number_of_adults }}</td>
                                <td style="width: 5%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ item.number_of_children }}</td>
                                <td style="width: 8%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ item.booking_type }}</td>
                                <td style="width: 8%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ item.tshirts }}</td>
                                <td style="width: 15%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ item.creator.name }}</td>
                                <td style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact;"></td>
                            </tr>
                        </table>
                        <div style="color: #808080;text-align: center;margin-top: 20px;font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;print-color-adjust: exact;">
                            <small>© NISHCINTO.COM. All rights reserved.</small>
                        </div>
                    </article>
                </section>
            </div>
            <template #footer>
                <Button label="Close" icon="pi pi-times" class="p-button-text" @click="hideConfirmList"/>
                <Button label="Print" icon="pi pi-print" class="p-button p-button-raised" @click="printTicket('Confirm List')"/>
            </template>
        </Dialog>

        <Dialog v-model:visible="openTicket" :style="{width: '950px'}" header="Ticket" :modal="true" class="p-fluid" :closable="false">
            <div class="grid-nogutter">
                <div class="col-12" id="printable">
                    <header style="text-align: center; margin-bottom: 10px;">
                        <table style="width:100%">
                            <tr>
                                <td style="text-align: left">
                                    <img v-if="agencyData.logo" :src="constant.imgUrl + agencyData.logo" alt="logo" role="presentation" height="80">
                                    <img v-else src="../../../assets/logo-light-256.png" alt="Nishcinto" height="80">
                                </td>
                                <td style="text-align: right;font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;" id="header-info">
                                    <div style="font-size: 12px;">{{formatDate(today)}}</div>
                                    <div style="font-size: 12px;">Tour No. T#000{{tour.id}}</div>
                                    <div style="font-size: 12px;">Booking No. BK#000{{ticketData.id}}</div>
                                </td>
                            </tr>
                        </table>
                        <div style="background-color: #4285F4;padding: 10px;text-align: center;font-size: 20px;color: #fff;print-color-adjust: exact;">{{ tour.title }}</div>
                    </header>
                    <section>
                        <article style="width: 100%;">
                            <table style="width:100%; border-collapse:separate; border-spacing: 10px;font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;">
                                <tr style="padding: 20px;">
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Tour starts</div>
                                        <div style="font-size: 14px;">{{ formatDate(tour.start_date) }}</div>
                                    </td>
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Tour ends</div>
                                        <div style="font-size: 14px;">{{ formatDate(tour.end_date) }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Starting point</div>
                                        <div style="font-size: 14px;">{{ tour.starting_point.line + ', ' + tour.starting_point.thana + ', ' + tour.starting_point.district }}</div>
                                    </td>
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Destination</div>
                                        <div style="font-size: 14px;">{{ tour.destination.line + ', ' + tour.destination.thana + ', ' + tour.destination.district }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Booking type</div>
                                        <div style="font-size: 14px;" v-if="ticketData.booking_type == 'Operator'">{{ ticketData.booking_type }}</div>
                                        <div style="font-size: 14px;" v-else>Customer</div>
                                    </td>
                                    <td>
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Booked by</div>
                                        <div style="font-size: 14px;">{{ ticketData.creator.name }}</div>
                                    </td>
                                    <td>
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Booking date</div>
                                        <div style="font-size: 14px;">{{ formatDate(ticketData.created_at) }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <hr>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Traveller Name</div>
                                        <div style="font-size: 14px;">{{ ticketData.traveller_name }}</div>
                                    </td>
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Traveller Email</div>
                                        <div style="font-size: 14px;">{{ ticketData.traveller_email }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Traveller Mobile</div>
                                        <div style="font-size: 14px;">{{ ticketData.traveller_mobile }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div style="font-family: 'Lucida Console', 'Courier New', monospace;font-size: 24px;font-weight: bold;">Adults x {{ ticketData.number_of_adults }}</div>
                                    </td>
                                    <td colspan="2">
                                        <div style="font-family: 'Lucida Console', 'Courier New', monospace;font-size: 24px;font-weight: bold;">Children x {{ ticketData.number_of_children }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <hr>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Total price</div>
                                        <div style="font-size: 14px;">{{ formatCurrency(ticketData.total_price) }}</div>
                                    </td>
                                    <td>
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Booking status</div>
                                        <div style="font-size: 14px;">{{ ticketData.status }}</div>
                                    </td>
                                    <td>
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Payment status</div>
                                        <div style="font-size: 14px;">{{ ticketData.paid == 1 ? 'Paid' : 'Due'}}</div>
                                    </td>
                                    <td>
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Payment method</div>
                                        <div style="font-size: 14px;">{{ ticketData.payment_method }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Transaction No.</div>
                                        <span v-if="ticketData.transactions.length > 0">
                                            <span v-for="(transaction, index) in ticketData.transactions" :key="index">
                                                <div v-if="transaction.pay_status == 'Successful'" style="font-size: 14px;">TR#000{{ transaction.id }}</div>
                                            </span>
                                        </span>
                                        <div v-else style="font-size: 14px;">-</div>
                                    </td>
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Transaction date</div>
                                        <span v-if="ticketData.transactions.length > 0">
                                            <span v-for="(transaction, index) in ticketData.transactions" :key="index">
                                                <div v-if="transaction.pay_status == 'Successful'" style="font-size: 14px;">{{ formatDate2(transaction.pay_time) }}</div>
                                            </span>
                                        </span>
                                        <div v-else style="font-size: 14px;">-</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Transaction medium</div>
                                        <div style="font-size: 14px;">{{ ticketData.transaction_type ? ticketData.transaction_type : '-' }}</div>

                                        <!-- <span v-if="ticketData.transactions.length > 0">
                                            <span v-for="(transaction, index) in ticketData.transactions" :key="index">
                                                <div v-if="transaction.val_status == 'VALID' || transaction.val_status == 'VALIDATED' || transaction.res == 'success'" style="font-size: 14px;">{{ transaction.card_issuer }}</div>
                                            </span>
                                        </span>
                                        <div v-else style="font-size: 14px;">-</div> -->
                                    </td>
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Transaction ID</div>
                                        <div style="font-size: 14px;">{{ ticketData.transaction_id ? ticketData.transaction_id : '-' }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <hr>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Tour agency</div>
                                        <div style="font-size: 14px;">{{ tour.agency.name }} (+880{{ tour.agency.primary_contact }})</div>
                                    </td>
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Tour manager</div>
                                        <div style="font-size: 14px;">{{ tour.manager.name }} ({{ tour.manager.mobile }})</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Tour type</div>
                                        <div style="font-size: 14px;">{{ tour.type.name }}</div>
                                    </td>
                                    <td colspan="2">
                                        <div style="color: #777;font-size: 12px;print-color-adjust: exact;">Tour category</div>
                                        <div style="font-size: 14px;">{{ tour.category.name }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <hr>
                                    </td>
                                </tr>
                            </table>
                            <div style="color: #808080;text-align: center;margin-top: 20px;font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;print-color-adjust: exact;">
                                <div>Team Nishcinto is wishing you a very happy journey!</div>
                                <small>© NISHCINTO.COM. All rights reserved.</small>
                            </div>
                        </article>
                    </section>
                </div>
            </div>
            <template #footer>
                <Button label="Close" icon="pi pi-times" class="p-button-text" @click="hideTicket"/>
                <Button label="Print" icon="pi pi-print" class="p-button p-button-raised" @click="printTicket('Ticket')"/>
            </template>
        </Dialog>

        <Dialog v-model:visible="openChallan" :style="{width: '950px'}" header="Challan" :modal="true" class="p-fluid" :closable="false">
            <div id="printable">
                <header style="text-align: center; margin-bottom: 15px;">
                    <table style="width: 100%; margin-bottom: 10px;">
                        <tr>
                            <td style="text-align: left">
                                <img v-if="agencyData.logo" :src="constant.imgUrl + agencyData.logo" alt="logo" role="presentation" height="80">
                                <img v-else src="../../../assets/logo-light-256.png" alt="Nishcinto" height="80">
                            </td>
                            <td style="text-align: right;font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;" id="header-info">
                                <div style="font-size: 12px;padding: 2px; font-weight: 600;">Challan</div>
                                <div style="font-size: 12px;padding: 2px;">{{formatDate(today)}}</div>
                                <div style="font-size: 12px;padding: 2px;">Tour No. <span style="font-weight: 600;">T#00{{tour.id}}</span></div>
                                <div style="font-size: 12px;padding: 2px;font-weight: 600;">{{tour.agency.name}}</div>
                            </td>
                        </tr>
                    </table>
                    <div style="background-color: #4285F4;padding: 10px;text-align: center;font-size: 20px;color: #fff;print-color-adjust: exact; margin: 8px 0;">{{ tour.title }}</div>
                    <hr>
                    <table style="width: 100%">
                        <tr>
                            <td style="font-size: 14px;padding: 2px; text-align: left">Total Bookings: <span style="font-weight: 600;">{{tour.seat_booked}}</span></td>
                            <td style="font-size: 14px;padding: 2px;">Total Bookings (Cash): <span style="font-weight: 600;">{{tour.seat_booked - products.totalBookingOnline}}</span></td>
                            <td style="font-size: 14px;padding: 2px; text-align: right">Total Bookings (Online): <span style="font-weight: 600;">{{products.totalBookingOnline}}</span></td>
                        </tr>
                        <tr>
                            <td style="font-size: 14px;padding: 2px; text-align: left">Total Balance: <span style="font-weight: 600;">{{products.totalBalance}}</span></td>
                            <td style="font-size: 14px;padding: 2px;">Total Balance (Cash): <span style="font-weight: 600;">{{products.totalCash}}</span></td>
                            <td style="font-size: 14px;padding: 2px; text-align: right">Total Balance (Online): <span style="font-weight: 600;">{{products.totalOnline}}</span></td>
                        </tr>
                    </table>
                    <hr>
                </header>
                <section>
                    <article style="width: 100%; margin-top: 8px;">
                        <table style="width: 100%; border: 1px solid;  border-color: #BDBDBD; border-collapse: collapse; font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;">
                            <tr style="border: 1px solid;  border-color: #BDBDBD; border-collapse: collapse;">
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">#</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Name</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Mobile</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Adults</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Child</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Type</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Payment</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Amount</th>
                                <th style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 2px; print-color-adjust: exact;">Booked By</th>
                            </tr>
                            <tr style="border: 1px solid; border-color: #BDBDBD; border-collapse: collapse;" v-for="(item, index) in products.bookings" :key="index">
                                <td style="width: 3%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ item.serial }}</td>
                                <td style="width: 22%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact;">{{ item.traveller_name }}</td>
                                <td style="width: 15%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ item.traveller_mobile }}</td>
                                <td style="width: 8%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ item.number_of_adults }}</td>
                                <td style="width: 8%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ item.number_of_children }}</td>
                                <td style="width: 9%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">
                                    <span v-if="item.booking_type == 'Operator'">{{ item.booking_type }}</span><span v-else>Customer</span>
                                </td>
                                <td style="width: 9%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{item.payment_method}}</td>
                                <td style="width: 8%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{item.total_paid}}</td>
                                <td style="width: 18%; border: 1px solid; border-color: #BDBDBD; border-collapse: collapse; font-size: 13px; padding: 10px 3px; print-color-adjust: exact; text-align: center;">{{ item.creator.name }}</td>
                            </tr>
                        </table>
                        <div style="color: #808080;text-align: center;margin-top: 20px;font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;print-color-adjust: exact;">
                            <small>© NISHCINTO.COM. All rights reserved.</small>
                        </div>
                    </article>
                </section>
            </div>
            <template #footer>
                <Button label="Close" icon="pi pi-times" class="p-button-text" @click="hideChallan"/>
                <Button label="Print" icon="pi pi-print" class="p-button p-button-raised" @click="printTicket('Challan')"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { ref, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Axios from 'axios';
import moment from 'moment';
import Constant from '../../../data/constant';
import { required, numeric, minValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
    setup () {
        const route = useRoute();
        const router = useRouter();
        const toast = useToast();
        const constant = ref(new Constant())
        const today = new Date();
        const pageLoading = ref(true);
		const pageValue = ref();
        const paginationValue = ref(10);

        const tour = ref();
        const agencyData = ref();
        const bookings = ref();
        const showBookingPanel = ref(false);
        const openList = ref(false);
        const openChallan = ref(false);
        const openTicket = ref(false);
        const bookingStatuses = ref(['Pending', 'Withdrawn', 'Declined', 'Requested for Cancellation', 'Canceled', 'Confirmed']);
        const bookingStatus = ref(null);
        const products = ref();

        const menu = ref();
        const seleectedBooking = ref();

        /** ----List Action--- */
        const toggle = (event, booking) => {
            seleectedBooking.value = booking;
            menu.value.toggle(event);
        };

        const items = ref([
            {
                label: 'View Payments',
                icon: 'pi pi-eye',
                command: () => {
                    getPaymentList(seleectedBooking.value);
                }
            },
            {
                label: 'Create Payment',
                icon: 'pi pi-plus',
                visible: () => seleectedBooking.value.total_paid != seleectedBooking.value.total_price,
                command: () => {
                    createPayment(seleectedBooking.value)
                }
            },
            {
                label: 'Show Ticket',
                icon: 'pi pi-ticket',
                visible: () => seleectedBooking.value.status == 'Confirmed',
                command: () => {
                    ticket(seleectedBooking.value)
                }
            },
        ]);

        /** ----List Action End--- */

        /**----Pagination and Query----- */
        const getActiveQuerys = () => {
            if(route.query.page != null){
                pageValue.value = route.query.page;
            }
            if(route.query.pagination != null){
                paginationValue.value = parseInt(route.query.pagination);
            }
            if(route.query.status != null){
                bookingStatus.value = route.query.status;
            }
        };
        getActiveQuerys();

        const onPage = (event) => {
			pageValue.value = event.page + 1;
			paginationValue.value = event.rows;
			pageLoading.value = true;

            var queryParams = {};
            if(pageValue.value != null){
                queryParams.page = pageValue.value;
            }
            if(paginationValue.value != null){
                queryParams.pagination = paginationValue.value;
            }
            router.replace({ name: 'booking', query: { ...queryParams } });

            getBookingList();
		};
        /**----Pagination and Query----- */
        
        /**----Tour and Agency Details----- */
        const getTourDetails = () => {
            Axios
                .get("api/operator/tour/view/"+route.params.slug)
                .then((res) =>{
                    if (res.data.response == "success") {
                        tour.value = res.data.data;
                        tour.value.manager.mobile = tour.value.manager.mobile ? formatMobileNumber(tour.value.manager.mobile) : '';
                    }else{
                        console.log(res);
                        showToast("error", "Error", res.data.message);
                    }
                })
                .catch((err) =>{
                    console.log(err);
                    showToast("error", "Error", "Could not fetch data. Try again!");
                });
        };
        getTourDetails();

        const getagencyData = () =>{
			Axios
			.get("api/operator/agency")
			.then((res) =>{
				if (res.data.response == "success") {
					agencyData.value = res.data.data;
				}
				else{
					console.log(res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getagencyData();
        /**----Tour and Agency Details End----- */

        /**----Booking List----- */
        const getBookingList = () => {
            Axios
                .get("api/operator/booking/list",{
                    params: {
                        page: pageValue.value,
                        pagination: paginationValue.value,
                        tour_id: route.params.id,
                        status: bookingStatus.value,
                    }
                })
                .then((res) =>{
                    if (res.data.response == "success") {
                        bookings.value = res.data.data;
                        for ( let booking of bookings.value.data ) {
                            booking.traveller_mobile = booking.traveller_mobile ? formatMobileNumber(booking.traveller_mobile) : '';
                        }
                        bookings.value.from = bookings.value.from-1;
                        pageLoading.value = false;
                    }else{
                        showToast("error", "Error", res.data.message);
                    }
                })
                .catch((err) =>{
                    showToast("error", "Error", "Something went wrong. Try again!");
                    console.log(err);
                });
        };
        getBookingList();

        const getFilteredBookingList = () => {
            pageLoading.value = true;

            var queryParams = {};
            if(bookingStatus.value != null){
                queryParams.status = bookingStatus.value;
            }
            // firstPage.value = 0;
            // currentPage.value = 0;

            // router.replace({ name: 'UserBookings', query: { ...queryParams } });

            getBookingList();
        };
        
        const bookingStatusColors =  (status) => {
            if (status == 'Pending') {
                return 'info';
            } else if (status == 'Withdrawn' || status == 'Requested for Cancellation') {
                return 'warning';
            } else if (status == 'Confirmed') {
                return 'success';
            } else {
                return 'danger';
            }
        };
        /**----Booking List----- */

        /**----PAYMENT SECTION----*/
        const paymentList = ref();
        const submitted = ref(false);
        const paymentLoading = ref(false);
        const openCreatePayment = ref(false);
        const openPaymentList = ref(false);

        const state = reactive({
            booking_id : null,
            date : null,
            amount : null,
            tour_id : null,
            user_id : null,
            note : null,
        });

        const rules = {
            booking_id : { required },
            date : { required },
            amount : { required, numeric, minValue : minValue(0) },
            tour_id : { required },
            user_id : { required },
            note : {  },
        };

        const v$ = useVuelidate(rules, state);

        const getPaymentList = (item) => {
            selectedItem.value = item;

            Axios
                .get('api/operator/payment/list-by-booking',{
                    params: {
                        booking_id: selectedItem.value.id,
                    }
                })
                .then((res) => {
                    if (res.data.response == "success") {
                        paymentList.value = res.data.data;
                        openPaymentList.value = true;
                    }else{
                        console.log(res);
                        showToast("error", "Error", res.data.message);
                    }
                })
                .catch((err) =>{
                    showToast("error", "Error", "Something went wrong. Try again!");
                    console.log(err);
                });
        }

        const createPayment = (item) => {
            selectedItem.value = item;
            state.booking_id = item.id;
            state.tour_id = item.tour_id;
            state.user_id = item.user_id;
            openCreatePayment.value = true;
        };

        const hideCreatePayment = () => {
            openCreatePayment.value = false;
            state.booking_id = null;
            state.tour_id = null;
            state.user_id = null;
            state.amount = null;
            state.date = null;
            selectedItem.value = null;
        };

        const hidePaymentList = () => {
            selectedItem.value = null;
            openPaymentList.value = false;
        };

        const paymentSubmission = (isFormValid) => {
            submitted.value = true;
            paymentLoading.value = true;

            console.log(state);
            if (!isFormValid) {
                return;
            }

            const formData = new FormData();

            formData.append("booking_id", state.booking_id);
            formData.append("date", moment(state.date).format("YYYY-MM-DD"));
            formData.append("amount", state.amount);
            formData.append("tour_id", state.tour_id);
            formData.append("user_id", state.user_id);
            if(state.note)
            formData.append("note", state.note);

            Axios
                .post('api/operator/payment/create', formData)
                .then( (res) => {
                    if (res.data.response == "success") {
                        showToast("success", "Success", res.data.message);
                        openCreatePayment.value = false;
                        submitted.value = false;
                        hideCreatePayment ();
                        getBookingList ();
                    } else {
                        showToast("error", "Error", res.data.message);
                        console.log(res);
                    }
                    paymentLoading.value = false;
                })
                .catch((err) => {
                    paymentLoading.value = false;
                    console.log(err);
                    showToast("error", "Error", "Booking failed. Try again!");
                });
        };

        /**----END PAYMENT----*/
        
        /**----BOOKING CANCELATION----*/
        const cancelDialog = ref(false);
        const cancelLoading = ref(false);
        const declineLoading = ref(false);
        const selectedItem = ref(null);

        const cancelBooking = (item) => {
            selectedItem.value = item;
            cancelDialog.value = true;
        };

        const closeCancellationDialog = () => {
            selectedItem.value = null;
        }

        const Cancellation = () => {
            cancelLoading.value = true;

            const formData = new FormData();

            formData.append("identifier", selectedItem.value.identifier);
            formData.append("cancelling_reason", selectedItem.value.cancelling_reason);

            Axios
                .post("api/operator/booking/cancel", formData)
                .then((res) => {
                    if (res.data.response == "success") {
                        showToast("success", "Success", res.data.message);
                        cancelDialog.value = false;
                        getBookingList();
                    } else {
                        showToast("error", "Error", res.data.message);
                    }
                    cancelLoading.value = false;
                })
                .catch((err) => {
                    cancelLoading.value = false;
                    console.log(err);
                    showToast("error", "Error", "Booking failed. Try again!");
                });
        };

        const DeclineCancellation = () => {
            declineLoading.value = true;

            const formData = new FormData();

            formData.append("identifier", selectedItem.value.identifier);

            Axios
                .post("api/operator/booking/decline-cancellation", formData)
                .then((res) => {
                    if (res.data.response == "success") {
                        showToast("success", "Success", res.data.message);
                        cancelDialog.value = false;
                        getBookingList();
                    } else {
                        showToast("error", "Error", res.data.message);
                    }
                    declineLoading.value = false;
                })
                .catch((err) => {
                    declineLoading.value = false;
                    console.log(err);
                    showToast("error", "Error", "Booking failed. Try again!");
                });
        };

        /**----BOOKING CANCELATION END----*/

        /**----CONFIRM LIST----*/
        const confirmList = () => {
            Axios
                .get("api/operator/booking/confirmed-list",{
                    params: {
                        tour_id: route.params.id,
                    }
                })
                .then((res) =>{
                    if (res.data.response == "success") {
                        products.value = res.data.data;
                        products.value.tshirtLength = 0;
                        for (let [index, item] of  products.value.bookings.entries()) {
                            item.serial = index+1;
                            item.traveller_mobile = item.traveller_mobile ? formatMobileNumber(item.traveller_mobile) : '';
                            if(item.tshirts) products.value.tshirtLength += 1
                        }
                        openList.value = true;
                    }else{
                        console.log(res);
                        showToast("error", "Error", res.data.message);
                    }
                })
                .catch((err) =>{
                    showToast("error", "Error", "Something went wrong. Try again!");
                    console.log(err);
                });
        };
        const hideConfirmList = () => {
            openList.value = false;
        }
        /**----CONFIRM LIST----*/

        /**----CHALLAN----*/
        const challan = () => {
            Axios
                .get("api/operator/booking/confirmed-list",{
                    params: {
                        tour_id: route.params.id,
                    }
                })
                .then((res) =>{
                    if (res.data.response == "success") {
                        products.value = res.data.data;
                        products.value.totalBalance = 0;
                        products.value.totalOnline = 0;
                        products.value.totalCash = 0;
                        products.value.totalBookingOnline = 0;
                        for (let [index, item] of  products.value.bookings.entries()) {
                            item.serial = index+1;
                            item.traveller_mobile = item.traveller_mobile ? formatMobileNumber(item.traveller_mobile) : '';
                            if (item.payment_method == 'Online') {
                                products.value.totalOnline += item.total_paid;
                                products.value.totalBookingOnline += (item.number_of_adults + item.number_of_children);
                            }else {
                                products.value.totalCash += item.total_paid;
                            }
                        }
                        products.value.totalBalance = products.value.totalCash + products.value.totalOnline;
                        products.value.totalBalance = parseFloat(products.value.totalBalance.toFixed(2));
                        openChallan.value = true;
                    }else{
                        console.log(res);
                        showToast("error", "Error", res.data.message);
                    }
                })
                .catch((err) =>{
                    showToast("error", "Error", "Something went wrong. Try again!");
                    console.log(err);
                });
        };

        const hideChallan = () => {
            openChallan.value = false;
        }
        /**----CHALLAN END----*/

        /**----TICKET----*/
        const ticketData = ref();

        const ticket = (e) => {
            ticketData.value = e;
            if (e.status == 'Confirmed' && e.payment_method == 'Online') {
                var tranTotal = 0;
                for(let transaction of e.transactions) {
                    if (transaction.pay_status == 'Successful') {
                        tranTotal += transaction.amount;
                    }
                }
                ticketData.value.total_price = tranTotal;
            }
            openTicket.value = true;
        }

        const ticketPrintLoading = ref(false);

        const printOperation = ref();

        const printTicket = (e) => {
            ticketPrintLoading.value = true;
            let contents = document.getElementById("printable").innerHTML;
            let frame1 = document.createElement('iframe');
            frame1.name = "frame1";
            frame1.style.position = "absolute";
            frame1.style.top = "-1000000px";
            document.body.appendChild(frame1);
            document.title = 'T#000'+tour.value.id +' '+ e;
            console.log(e);
            let frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
            frameDoc.document.open();
            frameDoc.document.write(contents);
            frameDoc.document.close();
            setTimeout(function () {
                window.frames["frame1"].focus();
                window.frames["frame1"].print();
                document.body.removeChild(frame1);
                ticketPrintLoading.value = false;
            }, 1000);
        }

        const hideTicket = () => {
            openTicket.value = false;
        }
        /**----TICKET END----*/

        
        /**Common Functions */
        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        };

        const timeDiff = (rawDate) => {
            return moment(rawDate).fromNow();
        };

        const formatDate = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY");
        };

        const formatDate2 = (rawDate) => {
            return moment(rawDate).format("DD-MM-YYYY hh:mm:ss A");
        };

        const formatCurrency = (rawDate) => {
			if(rawDate)
				return rawDate.toLocaleString('en-US', {style: 'currency', currency: 'BDT'});
			return;
		};

        const formatMobileNumber = (number) => {
            return '0' + number.toString().slice(-10);
        };
        /**Common Functions End*/

        return {
            pageLoading,
            constant,
            showBookingPanel,
            tour,
            bookings,
            timeDiff,
            formatDate,
            formatDate2,
            formatCurrency,
            bookingStatusColors,
            getFilteredBookingList,
            bookingStatuses,
            bookingStatus,
            cancelBooking,
            cancelDialog,
            Cancellation,
            DeclineCancellation,
            closeCancellationDialog,
            cancelLoading,
            declineLoading,
            selectedItem,
            onPage,
            paginationValue,
            pageValue,
            route,
            today,

            confirmList,
            challan,
            ticket,
            ticketData,
            openList,
            openChallan,
            openTicket,
            products,
            hideConfirmList,
            hideChallan,
            hideTicket,
            printTicket,
            ticketPrintLoading,
            agencyData,

            openCreatePayment,
            createPayment,
            hideCreatePayment,
            v$,
            submitted,
            paymentSubmission,
            paymentLoading,

            getPaymentList,
            openPaymentList,
            hidePaymentList,
            paymentList,

            items,
            menu,
            toggle,
            formatMobileNumber,
            printOperation
        }
    },
}
</script>

<style lang="scss" scoped>
    .booking-item {
        border: 1px solid var(--surface-border);
        border-radius: 8px;
    }
</style>